/* eslint-disable */

import { StackDetailRouteParams } from '.';
import {
  pipelinesActions,
  runPagesActions,
  stacksActions,
} from '../../../../redux/actions';
import { stackSelectors } from '../../../../redux/selectors';
import { useParams, useSelector } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { stackPagesActions } from '../../../../redux/actions';
import { useEffect } from 'react';
import { filterObjectForParam } from '../../../../utils';
import { Stack } from '../../../../api/types';

interface ServiceInterface {
  stack: Stack;
}

export const useService = (): ServiceInterface => {
  const dispatch = useDispatch();
  const { id } = useParams<StackDetailRouteParams>();
  const ITEMS_PER_PAGE = parseInt(
    process.env.REACT_APP_ITEMS_PER_PAGE as string,
  );
  const DEFAULT_ITEMS_PER_PAGE = 10;
  // useEffect(() => {
  //   setFetching(true);

  //   dispatch(
  //     stacksActions.stackForId({
  //       stackId: id,
  //       onSuccess: () => setFetching(false),
  //       onFailure: () => setFetching(false),
  //     }),
  //   );
  //   // Legacy: previously runs was in pipeline
  //   dispatch(
  //     stacksActions.allRunsByStackId({
  //       sort_by: 'desc:created',
  //       logical_operator: 'and',
  //       page: 1,
  //       size: ITEMS_PER_PAGE ? ITEMS_PER_PAGE : DEFAULT_ITEMS_PER_PAGE,
  //       stackId: id,
  //       onSuccess: () => setFetching(false),
  //       onFailure: () => setFetching(false),
  //     }),
  //   );
  // }, [id]);

  // const setFetching = (fetching: boolean) => {
  //   dispatch(runPagesActions.setFetching({ fetching }));
  // };

  const stack = useSelector(stackSelectors.stackForId(id));

  return { stack };
};

export const callActionForStackRunsForPagination = () => {
  const dispatch = useDispatch();

  function dispatchStackRunsData(
    id: any,
    page: number,
    size: number,
    filters?: any[],
    sortby?: string,
  ) {
    const logicalOperator = localStorage.getItem('logical_operator');
    let filtersParam = filterObjectForParam(filters);

    setFetching(true);
    dispatch(
      stacksActions.allRunsByStackId({
        sort_by: sortby ? sortby : 'created',
        logical_operator: logicalOperator ? JSON.parse(logicalOperator) : 'and',
        stackId: id,
        page: page,
        size: size,
        filtersParam,
        onSuccess: () => setFetching(false),
        onFailure: () => setFetching(false),
      }),
    );
  }

  const setFetching = (fetching: boolean) => {
    dispatch(runPagesActions.setFetching({ fetching }));
  };

  return {
    setFetching,
    dispatchStackRunsData,
  };
};
